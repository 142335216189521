import { apiResponseHandler, getHeaders } from '../../api-utils'
import { ENDPOINT_BASE_URL } from '../deposits/api'
import { ENDPOINT_BASE_URL as CATEGORIES_ENDPOINT_BASE_URL} from '../categories/api'
import { ENDPOINT_BASE_URL as SORTING_KEY_ENDPOINT_BASE_URL } from '../sorting/api'

export const createBarcode = async ({
  accessTokenPromise,
  depositId,
  fields,
}) => fetch(

  `${ENDPOINT_BASE_URL}/${depositId}/barcodes`,
  {
    body: JSON.stringify({
      depositId,
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  },
).then(apiResponseHandler)

export const updateBarcode = async ({
  accessTokenPromise,
  depositId,
  fields,
}) => fetch(
  `${ENDPOINT_BASE_URL}/${depositId}/barcodes/${fields.id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const deleteBarcode = async ({
  accessTokenPromise,
  barcodeId,
  depositId,
}) => fetch(
  `${ENDPOINT_BASE_URL}/${depositId}/barcodes/${barcodeId}`,
  {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const getDeposit = async ({ accessTokenPromise, depositId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${depositId}`

  return fetch(
    endpoint,
    { headers: getHeaders(await accessTokenPromise) },
  )
    .then(res => res.json())
}

export const createDepositMedia = async ({ accessTokenPromise, media, depositId }) => {
  const formData = new FormData()
  formData.append('media', media.file)

  return fetch(
    `${ENDPOINT_BASE_URL}/${depositId}/media`,
    {
      body: formData,
      headers: {
        Authorization: `Bearer ${await accessTokenPromise}`,
      },
      method: 'POST',
    },
  ).then(apiResponseHandler)
}

export const deleteDepositMedia = async ({ accessTokenPromise, name, depositId }) => fetch(
  `${ENDPOINT_BASE_URL}/${depositId}/media`,
  {
    body: JSON.stringify({
      filename: name,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const getCategories = async ({ accessTokenPromise }) => fetch(
  CATEGORIES_ENDPOINT_BASE_URL,
  { headers: getHeaders(await accessTokenPromise) },
).then(res => res.json())

export const getSortingKeys = async ({ accessTokenPromise }) => fetch(
  SORTING_KEY_ENDPOINT_BASE_URL,
  { headers: getHeaders(await accessTokenPromise) },
).then(res => res.json())
