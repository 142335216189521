import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { useParams } from 'react-router-dom'
import {
  Button,
  Col,
  Descriptions,
  Empty,
  Modal,
  notification,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
  Upload,
} from 'antd'
import {
  createBarcode,
  createDepositMedia,
  deleteBarcode,
  deleteDepositMedia,
  getDeposit,
  updateBarcode,
} from './api'
import { BarcodeModal } from './BarcodeModal'
import { useAuth0 } from '../../auth0'
import { Can, ErrorMessage, Icon } from '../../components'
import { AVAILABILITY_STATUS } from '../../constants'
import { routePropTypes } from '../../types'
import './DepositDetails.css'

const { Title } = Typography
const { confirm } = Modal

const DepositDetailsPage = () => {
  const { id: depositId } = useParams()
  const [isBarcodeModalVisible, setBarcodeModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedBarcode, setSelectedBarcode] = useState(null)
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()
  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    depositId,
    promiseFn: getDeposit,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  if (isLoading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  const tableColumns = [
    {
      className: 'depositDetailBarcodeIdSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'Barcode ID',
    },
    {
      className: 'depositDetailProductNameSelenium',
      dataIndex: 'product_name',
      key: 'product_name',
      title: 'Product name',
    },
    {
      className: 'depositDetailBarcodeValueSelenium',
      dataIndex: 'value',
      key: 'value',
      title: 'Barcode value',
    },
    {
      className: 'depositDetailCategorySelenium',
      dataIndex: 'categories',
      key: 'categories',
      render: (_, record) => record.categories.map(elem => elem.title).join(', '),
      title: 'Categories',
    },
    {
      className: 'depositDetailSortingKeySelenium',
      dataIndex: 'sorting_key',
      key: 'sorting_key',
      render: (sorting_key) => sorting_key.title,
      title: 'Sorting key',
    },
    {
      className: 'availabilityStatusSelenium',
      dataIndex: 'availability_status',
      key: 'availability_status',
      render: (text) => AVAILABILITY_STATUS[text],
      title: 'Availability Status',
    },
    {
      className: 'depositDetailActionSelenium',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:barcodes"
            yes={() => (
              <Button
                name="updateBarcodeSelenium"
                onClick={() => handleUpdate(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />
          {
            data.barcodes.length > 1 &&
            (
              <Can
                requiredPermission="delete:barcodes"
                yes={() => (
                  <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete this barcode?"
                  >
                    <Button
                      danger
                      ghost
                      name="deleteBarcodeSelenium"
                      size="small"
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              />
            )
          }
        </span>
      ),
      title: 'Action',
      width: 150,
    },
  ]

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setErrorMessage(null)
    setBarcodeModalVisibility(false)

    notification.open({
      description: 'Barcode is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.error(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Barcode creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateBarcode({
        accessTokenPromise,
        depositId,
        fields: payload,
      })
        .then(() => handleSuccess({
          description: 'Barcode is updated successfully',
        }))
        .catch((error) =>
          handleFailure(error, { description: 'Barcode update is failed.' }),
        )

    case 'CREATE':
      return createBarcode({
        accessTokenPromise,
        depositId: data.id,
        fields: payload,
      })
        .then(handleSuccess)
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleDelete = (barcode) => {
    deleteBarcode({ accessTokenPromise, barcodeId: barcode.id, depositId })
      .then(() => handleSuccess({ description: 'Barcode is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Barcode removal is failed.' }))
  }

  const handleAddClick = () => {
    setSelectedBarcode(null)
    setErrorMessage(null)
    setBarcodeModalVisibility(true)
    setModalLoading(false)
  }

  const handleUpdate = (barcode) => {
    setSelectedBarcode(barcode)
    setErrorMessage(null)
    setBarcodeModalVisibility(true)
    setModalLoading(false)
  }

  const handleMediaUpload = (media) => {
    createDepositMedia({ accessTokenPromise, depositId, media })
      .then(() => (
        handleSuccess({ description: 'Media file is created successfully.' })),
      )
      .catch((error) => {
        handleFailure(error, { description: `Media file creation is failed. ${error}` })
        reload()
      })
  }

  const handleMediaRemove = ({ name }) => (
    new Promise((resolve) => {
      confirm({
        onOk: () => {
          deleteDepositMedia({ accessTokenPromise, depositId, name })
            .then((result) => {
              handleSuccess({ description: 'Media file is removed successfully.' })

              resolve(true)
            })
            .catch((error) => {
              handleFailure(error, { description: `Media file removal is failed. ${error}` })
              reload()

              resolve(false)
            })
        },
        title: 'Are you sure you want to delete this media file?',
      })
    })
  )

  const mediaFilesWithUId = data.media.map(media => ({
    ...media,
    uid: media.name,
  }))

  return (
    <div>
      <Title>
        Deposit Details
      </Title>

      <Descriptions
        bordered
        className="depositDetailsTable"
        size="middle"
      >
        <Descriptions.Item label="Package Name" span={3}>
          {data.package.name}
        </Descriptions.Item>

        <Descriptions.Item label="Country" span={3}>
          {data.country}
        </Descriptions.Item>

        <Descriptions.Item label="Currency">
          {data.currency}
        </Descriptions.Item>

        <Descriptions.Item label="Net Amount">
          {data.net_amount}
        </Descriptions.Item>

        <Descriptions.Item label="Tax Amount">
          {data.tax_amount}
        </Descriptions.Item>

        <Descriptions.Item label="Deposit Description" span={3}>
          {data.description}
        </Descriptions.Item>

        <Descriptions.Item label="Package Description" span={3}>
          {data.package.description}
        </Descriptions.Item>

        <Descriptions.Item label="Images" span={3}>
          <Can
            no={() => (
              <Upload
                defaultFileList={mediaFilesWithUId}
                disabled={true}
              >
                <Button name="disabledUploadDepositImageSelenium">
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
            requiredPermission="create:media"
            yes={() => (
              <Upload
                customRequest={handleMediaUpload}
                defaultFileList={mediaFilesWithUId}
                onRemove={handleMediaRemove}
              >
                <Button name="uploadDepositImageSelenium">
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Supplier" span={3}>
          {data?.supplier?.name}
        </Descriptions.Item>

        <Descriptions.Item className="retailersList" label="Available at:">
          {
            data.retailers.map((field) => (
              <span key={field.id}>{field.name}</span>
            ))
          }
        </Descriptions.Item>
      </Descriptions>

      <Row
        align="middle"
        justify="space-between"
        style={{ margin: '20px 0 10px' }}
        type="flex"
      >
        <Col>
          <Title level={4}>
            Barcodes
          </Title>
        </Col>

        <Col style={{ marginBottom: '10px' }}>
          <Can
            requiredPermission="create:barcodes"
            yes={() => (
              <Button
                name="addNewBarcodeSelenium"
                onClick={handleAddClick}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>
      </Row>

      <Table
        bordered
        className="depositDetailsBarcodeTableSelenium"
        columns={tableColumns}
        dataSource={data.barcodes || []}
        locale={{ emptyText: <Empty description="No barcode" /> }}
        pagination={false}
        rowKey="id"
      />

      {isBarcodeModalVisible &&
        <BarcodeModal
          errorMessage={errorMessage}
          hideModal={() => setBarcodeModalVisibility(false)}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedBarcode={selectedBarcode}
          selectedCategories={selectedBarcode?.categories}
          selectedSortingKey={selectedBarcode?.sorting_key}
          setModalLoading={setModalLoading}
          setVisibility={setBarcodeModalVisibility}
        />
      }
    </div>
  )
}

DepositDetailsPage.propTypes = {
  ...routePropTypes,
}

export default DepositDetailsPage
