import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Form, Input, Modal, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { isEmpty, last } from 'lodash'
import { formPropTypes } from '../../types'

const SortingKeyModalFrom = ({
  errorMessage,
  handleMediaRemove,
  isModalLoading,
  onSubmit,
  selectedKey,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedKey
  const [mediaFile, setMediaFile] = useState(null)

  const { getFieldsValue, isFieldsTouched } = form

  const handleOk = () => {
    const isKeyNewOrEdited = isFieldsTouched() || !isUpdate

    if (isKeyNewOrEdited) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue(['id', 'title'])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedKey.id : undefined

          onSubmit(actionType, { ...fieldsValue, id, media: mediaFile })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleChange = ({ file }) => {
    setMediaFile(file)
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelSortingKeyModalBtnSelenium' }}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateSortingKeySelenium' : 'addSortingKeySelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update sorting key' : 'Add a new sorting key'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedKey.title : undefined}
          label="Sorting key name"
          name="title"
          rules={[{ message: 'Please add a sorting key name!', required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={isUpdate ? selectedKey?.media : undefined}
          label="Sorting key image"
          name="media"
        >
          <Upload
            beforeUpload={() => false}
            fileList={mediaFile && mediaFile.status !== 'removed' ? [mediaFile] : !isEmpty(selectedKey?.media) ? [last(selectedKey?.media)] : []}
            onChange={handleChange}
            onRemove={!isEmpty(selectedKey?.media) ? () => handleMediaRemove(selectedKey) : () => setMediaFile(null)}
          >
            {((isEmpty(selectedKey?.media) && !mediaFile?.name)) && (
              <Button icon={<UploadOutlined />} type="primary">
                Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
      {errorMessage && (
        <Alert description={errorMessage} message="Error" showIcon type="error" />
      )}
    </Modal>
  )
}

SortingKeyModalFrom.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  handleMediaRemove: PropTypes.func,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedKey: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const SortingKeyModal = SortingKeyModalFrom
