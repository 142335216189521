import React, { useState } from 'react'
import { useAsync } from 'react-async'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Empty,
  notification,
  Popconfirm,
  Row,
  Typography,
} from 'antd'
import { createSupplier, deleteSupplier, getSuppliers, updateSupplier } from './api'
import { SupplierModal } from './SupplierModal'
import { Can, CustomTable, ErrorMessage, Icon } from '../../components'
import { routePropTypes } from '../../types'
import { convertToFlatArray, getTableHeaderSearchInput, useCustomSearchParams } from '../../utils'
import { useAuth0 } from '../../auth0'
import './SuppliersPage.css'

const { Title } = Typography

const Supplier = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useCustomSearchParams()
  const [ isSupplierModalVisible, setSupplierModalVisibility ] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedSupplier, setSelectedSupplier ] = useState(null)
  const nameFilter = convertToFlatArray(searchParams.name)

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    nameFilter,
    promiseFn: getSuppliers,
    watch: location.search,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const suppliers = (data && data.suppliers) || []

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setErrorMessage(null)
    setSupplierModalVisibility(false)

    notification.open({
      description: 'Supplier is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    setErrorMessage(error.message)
    setModalLoading(false)

    console.log(error)

    notification.open({
      description: 'Supplier creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }
  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateSupplier({
        accessTokenPromise,
        id: payload.id,
        value: payload.fieldValue,
      })
        .then(() => handleSuccess({
          description: 'Supplier is updated successfully',
        }))
        .catch((error) =>
          handleFailure(error, { description: 'Supplier update is failed.' }),
        )

    case 'CREATE':
      return createSupplier({ accessTokenPromise, value: payload.fieldValue })
        .then(handleSuccess)
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleAddClick = () => {
    setSelectedSupplier(null)
    setErrorMessage(null)
    setSupplierModalVisibility(true)
    setModalLoading(false)
  }

  const handleUpdateClick = (supplier) => {
    setSelectedSupplier(supplier)
    setErrorMessage(null)
    setSupplierModalVisibility(true)
    setModalLoading(false)
  }

  const handleRemoveClick = (id) => {
    deleteSupplier({ accessTokenPromise, id })
      .then(() => handleSuccess({ description: 'Supplier is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Supplier removal is failed.' }))
  }

  const handleTableChange = (_, filters) => {
    navigate({
      ...location,
      search: queryString.stringify(filters),
    })
  }

  const columns = [
    {
      className: 'supplierIdSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'suppliersNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.name ? [searchParams.name] : null,
      key: 'name',
      title: 'Name',
      ...getTableHeaderSearchInput('suppliersNameSearchSelenium', 'name'),
    },
    {
      className: 'numberOfDepositsSelenium',
      dataIndex: 'number_of_deposits',
      key: 'number_of_deposits',
      title: 'Number of deposits',
    },
    {
      className: 'supplierPageActionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:suppliers"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateSupplierSelenium"
                onClick={() => handleUpdateClick(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />

          <Can
            requiredPermission="delete:suppliers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={record.number_of_deposits > 0}
                okText="Yes"
                onConfirm={() => handleRemoveClick(record.id)}
                title="Are you sure you want to delete this supplier?"
              >
                <Button
                  danger
                  disabled={record.number_of_deposits > 0}
                  ghost
                  name="deleteSupplierSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: 150,
    },
  ]

  return (
    <div>
      <Title>Suppliers</Title>
      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:suppliers"
            yes={() => (
              <Button name="addNewSupplierSelenium" onClick={handleAddClick} type="primary">
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            bordered
            className="suppliersPageTableSelenium x-scroll"
            columns={columns}
            dataSource={suppliers}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No supplier" /> }}
            onChange={handleTableChange}
            rowKey="id"
          />
        </Col>
      </Row>

      {isSupplierModalVisible && (
        <SupplierModal
          addSupplier={handleAddClick}
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedSupplier={selectedSupplier}
          setModalLoading={setModalLoading}
          setVisibility={setSupplierModalVisibility}
        />
      )}
    </div>
  )
}

Supplier.propTypes = {
  ...routePropTypes,
}

export default Supplier
