import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '../components'

const menuElements = [
  {
    icon: <Icon type="dashboard" />,
    key: '/',
    label: (<Link to="/">Dashboard</Link>),
    name: 'dashboardSelenium',
    permission: [],
  },
  {
    icon: <Icon type="car" />,
    key: '/suppliers',
    label: (<Link to="/suppliers">Suppliers</Link>),
    name:'suppliersSelenium',
    permission: ['read:suppliers'],
  },
  {
    icon: <Icon type="bank" />,
    key: '/brands',
    label: (<Link to="/brands">Brands</Link>),
    name:'brandsSelenium',
    permission: ['read:brands'],
  },
  {
    icon: <Icon type="appstore" />,
    key: '/packages',
    label: (<Link to="/packages">Packages</Link>),
    name:'packagesSelenium',
    permission: ['read:packages'],
  },
  {
    icon: <Icon type="upload" />,
    key: '/deposits',
    label: (<Link to="/deposits">Deposits</Link>),
    name:'depositsSelenium',
    permission: ['read:deposits'],
  },
  {
    icon: <Icon type="barcode" />,
    key: '/categories',
    label: (<Link to="/categories">Categories</Link>),
    name:'categoriesSelenium',
    permission: ['read:categories'],
  },
  {
    icon: <Icon type="shop" />,
    key: '/retailers',
    label: (<Link to="/retailers">Retailers</Link>),
    name:'retailersSelenium',
    permission: ['read:retailers'],
  },
  {
    icon: <Icon type="sorting" />,
    key: '/sorting',
    label: (<Link to="/sorting">Sorting Key</Link>),
    name:'sortingKeySelenium',
    permission: ['read:sorting_keys'],
  },
]

export default menuElements
