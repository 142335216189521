import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { Button, Checkbox, DatePicker, Input } from 'antd'
import { isEmpty } from 'lodash'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { Icon } from '../../components'

const { RangePicker } = DatePicker

export const useCustomSearchParams = (defaultParams) => {
  const [search, setSearch] = useSearchParams()

  useEffect(() => {
    if (isEmpty(search.toString()) && defaultParams)
      setSearch(defaultParams)
  }, [defaultParams, search, setSearch])

  const searchAsObject = queryString.parse(search.toString())

  return [searchAsObject, setSearch]
}

export const getTableHeaderDatePicker = (name) => {
  const handleReset = (filter) => {
    filter.clearFilters()
    filter.confirm()
  }

  return {
    filterDropdown: (filter) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          onChange={(_, dateString) => filter.setSelectedKeys(dateString)}
          style={{ display: 'block', marginBottom: 8, width: 300 }}
          value={[dayjs(filter.selectedKeys[0]), dayjs(filter.selectedKeys[1])]}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            className="ant-btn ant-btn-link ant-btn-sm"
            name="datePickerButtonResetSelenium"
            onClick={() => handleReset(filter)}
            size="small"
          >
            Reset
          </span>
          <Button
            name="datePickerButtonSearchSelenium"
            onClick={() => filter.confirm({ closeDropdown: true })}
            size="small"
            style={{ marginLeft: '20px' }}
            type="primary"
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: <Icon name={name} type="calendar" />,
  }
}

export const SearchInputFilter = ({ filter, searchParam }) => {
  const [search, setSearch] = useCustomSearchParams()

  const handleReset = (filter) => {
    const tempParams = { ...search }
    delete tempParams[searchParam]

    setSearch({
      ...tempParams,
      ...(search.modal_page && { modal_page: 1 }),
      page: 1,
    })

    filter.clearFilters()
    filter.confirm()
  }

  const handleSearch = (filter) => {
    setSearch({
      ...search,
      ...(search.modal_page && { modal_page: 1 }),
      page: 1,
      [searchParam]: filter.selectedKeys,
    })

    filter.confirm()
  }

  return (
    <div name="customFilterWrapper" style={{ padding: 8 }}>
      <Input
        autoFocus
        onChange={e => filter.setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onKeyDown={(e) => e.stopPropagation()}
        onPressEnter={() => handleSearch(filter)}
        placeholder="Search"
        style={{ display: 'block', marginBottom: 8, width: 188 }}
        value={filter.selectedKeys[0]}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          className="ant-btn ant-btn-link ant-btn-sm"
          disabled={!filter.selectedKeys[0]}
          name="searchFilterResetButtonSelenium"
          onClick={() => handleReset(filter)}
          size="small"
        >
          Reset
        </span>
        <Button
          name="searchFilterButtonSelenium"
          onClick={() => handleSearch(filter)}
          size="small"
          style={{ marginLeft: '20px' }}
          type="primary"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

SearchInputFilter.propTypes = {
  filter: PropTypes.object,
  name: PropTypes.string,
  searchParam: PropTypes.string,
}

export const getTableHeaderSearchInput = (name, key) => {
  return {
    filterDropdown: (filter) => (
      <SearchInputFilter filter={filter} name={name} searchParam={key} />
    ),
    filterIcon: <SearchOutlined name={name} />,
  }
}

export const CheckboxFilter = ({ filter, filters, name, param, type }) => {
  const [search, setSearch] = useCustomSearchParams()

  let initialFilters = {}
  let clearedFilters = {}

  filters.forEach(f => {
    initialFilters[f.value] = { ...f, selected: !!~filter.selectedKeys.indexOf(f.value.toString()) }
    clearedFilters[f.value] = { ...f, selected: false }
  })

  const [filterState, setFilterState] = useState(initialFilters)

  const handleReset = (filter) => {
    setFilterState({ ...clearedFilters })

    let tempParams = { ...search }

    tempParams[`${param}[]`]
      ? delete tempParams[`${param}[]`]
      : delete tempParams[param]

    setSearch({
      ...tempParams,
      ...(search.modal_page && { modal_page: 1 }),
      modal_page: tempParams.modal_page ? 1 : null,
      page: 1,
    })

    filter.clearFilters()
    filter.confirm()
  }

  const handleSelect = (filter, e) => {
    filterState[e.target.name].selected = !filterState[e.target.name].selected
    const multiOption = type !== 'single'

    if (type === 'single') {
      Object.keys(filterState).forEach(filter => {if (filterState[filter].value !== e.target.name) {filterState[filter].selected = false}})
    }

    const tempKeys = Object.values(filterState).filter(elem => elem.selected && (multiOption || e.target.name === elem.value)).map(el => el.value)
    filter.setSelectedKeys(tempKeys)
  }

  const handleSearch = () => {
    setSearch({
      ...search,
      ...(search.modal_page && { modal_page: 1 }),
      page: 1,
      [param]: filter.selectedKeys,
    })

    filter.confirm()
  }

  return (
    <div className={`${name} ant-table-filter-dropdown`}>
      <ul className="ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical">
        {(filters ?? []).map(option =>
          <li className="ant-dropdown-menu-item" key={option.value} role="menuitem">
            <Checkbox checked={filterState[option.value]?.selected} data-testid={option.value} name={option.value} onChange={e => handleSelect(filter, e)}>{option.text}</Checkbox>
          </li>,
        )}
      </ul>

      <div className="ant-table-filter-dropdown-btns" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          className="ant-btn ant-btn-link ant-btn-sm"
          disabled={!filter.selectedKeys[0]}
          name="searchFilterResetButtonSelenium"
          onClick={() => handleReset(filter)}
          size="small"
        >
          Reset
        </span>
        <Button
          name="searchFilterButtonSelenium"
          onClick={() => handleSearch()}
          size="small"
          style={{ marginLeft: '20px' }}
          type="primary"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

CheckboxFilter.propTypes = {
  filter: PropTypes.object,
  filters: PropTypes.array,
  name: PropTypes.string,
  param: PropTypes.string,
  type: PropTypes.string,
}

export const getTableHeaderCheckboxFilter = (name, filters, param, type) => {
  if (filters.length > 0)
    return {
      filterDropdown: (filter) => (
        <CheckboxFilter filter={filter} filters={filters} name={name} param={param} type={type} />
      ),
      filterIcon: <FilterOutlined name={name} />,
    }
}

export default getTableHeaderDatePicker
