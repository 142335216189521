import queryString from 'query-string'
import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/sorting_keys'

export const getSortingKeys = async ({
  accessTokenPromise,
  itemsPerPage,
  page,
  sortBy,
  titleFilter,
}) => {
  const queryParams = queryString.stringify(
    {
      items_per_page: itemsPerPage,
      page,
      sort_by: sortBy,
      title: titleFilter,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`
  const accessToken = await accessTokenPromise

  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(apiResponseHandler)
}

export const updateSortingKey = async ({
  accessTokenPromise,
  fields,
  id,
}) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const createSortingKey = async ({ accessTokenPromise, fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  },
).then(apiResponseHandler)

export const deleteSortingKey = async ({ accessTokenPromise, id }) => fetch(
  `${ENDPOINT_BASE_URL}/${id}`,
  {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const addMediaToSortingKey = async ({ accessTokenPromise, media, sortingKeyId }) => {
  const formData = new FormData()
  formData.append('media', media.file)

  return fetch(
    `${ENDPOINT_BASE_URL}/${sortingKeyId}/media`,
    {
      body: formData,
      headers: {
        Authorization: `Bearer ${await accessTokenPromise}`,
      },
      method: 'POST',
    },
  ).then(apiResponseHandler)
}

export const deleteMediaFromSortingKey = async ({ accessTokenPromise, name, sortingKeyId }) => fetch(
  `${ENDPOINT_BASE_URL}/${sortingKeyId}/media`,
  {
    body: JSON.stringify({
      filename: name,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  },
).then(apiResponseHandler)
