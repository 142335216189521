import React, { forwardRef } from 'react'
import { Spin, Select } from 'antd'
import { useAuth0 } from '../../auth0'
import { useAsync } from 'react-async'
import { getSortingKeys } from '../../pages/deposit-details/api'

const { Option } = Select

const SortingKeySelector = forwardRef(({ ...props }, ref) => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    promiseFn: getSortingKeys,
  })

  if (!data || isLoading) return <Spin size="small" />

  if (error) {
    return <div>An error occurred while fetching sorting keys.</div>
  }

  return (
    <Select
      allowClear
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Please select"
      showSearch
      style={{ width: '100%' }}
      {...props}
    >
      {data?.sorting_keys.map(({ id, title }) =>
        <Option
          key={id}
          name="sortingKeyOption"
          value={id}
        >
          {title}
        </Option>,
      )}
    </Select>
  )
})

export default SortingKeySelector
