import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { last } from 'lodash'
import { Row, Col, Button, notification, Popconfirm, Typography } from 'antd'
import { useAuth0 } from '../../auth0'
import { useLocation } from 'react-router-dom'
import { convertToFlatArray, getTableHeaderSearchInput, useCustomSearchParams } from '../../utils'
import { Can, CustomTable, CustomPagination, ErrorMessage, Icon } from '../../components'
import { addMediaToSortingKey, createSortingKey, deleteSortingKey, deleteMediaFromSortingKey, getSortingKeys, updateSortingKey } from './api'
import { SortingKeyModal } from './SortingKeyModal'

const { Title } = Typography

const SortingKeyPage = () => {
  const location = useLocation()
  const [searchParams] = useCustomSearchParams()

  const [ isSortingKeyModalVisible, setSortingKeyModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedKey, setSelectedKey ] = useState(null)

  const page = searchParams.page || 1
  const titleFilter = convertToFlatArray(searchParams.title)
  const sortBy = searchParams.sort_by || 'title'
  const itemsPerPage = searchParams.items_per_page|| 25

  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    itemsPerPage,
    page,
    promiseFn: getSortingKeys,
    sortBy,
    titleFilter,
    watch: location.search,
  })

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setErrorMessage(null)
    setSortingKeyModalVisibility(false)

    notification.open({
      description: 'Sorting key is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    setErrorMessage(error.message)
    setModalLoading(false)
    console.log(error)

    notification.open({
      description: 'Sorting key creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'UPDATE':
      return updateSortingKey({
        accessTokenPromise,
        fields: payload,
        id: payload.id,
      })
        .then(() => {
          if (payload.media) {
            handleMediaUpload({ file: payload.media, sortingKeyId: payload.id })
          } else {
            handleSuccess()
          }})
        .catch((error) =>
          handleFailure(error, { description: 'Sorting key update is failed.' }),
        )

    case 'CREATE':
      return createSortingKey({
        accessTokenPromise,
        fields: payload,
      })
        .then((newSortingKey) => {
          if (payload.media) {
            handleMediaUpload({ file: payload.media, sortingKeyId: newSortingKey.id })
          } else {
            handleSuccess()
          }})
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleAddClick = () => {
    setSelectedKey(null)
    setErrorMessage(null)
    setSortingKeyModalVisibility(true)
    setModalLoading(false)
  }

  const handleUpdateClick = (key) => {
    setSelectedKey(key)
    setErrorMessage(null)
    setSortingKeyModalVisibility(true)
    setModalLoading(false)
  }

  const handleDelete = (id) => {
    deleteSortingKey({ accessTokenPromise, id })
      .then(() => handleSuccess({ description: 'Sorting key is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Sorting key removal is failed.' }))
  }

  const handleMediaUpload = (media) => {
    setModalLoading(true)
    addMediaToSortingKey({ accessTokenPromise, media, sortingKeyId: media.sortingKeyId })
      .then(() => {
        handleSuccess({ description: 'Media file is created successfully.' })
        setModalLoading(false)
      })
      .catch((error) => {
        handleFailure(error, { description: `Media file creation is failed. ${error}` })
        setModalLoading(false)
        reload()
      })
  }

  const handleMediaRemove = (record) => {
    setModalLoading(true)
    deleteMediaFromSortingKey({ accessTokenPromise, name: last(record?.media).name, sortingKeyId: record.id })
      .then(() => {
        handleSuccess({ description: 'Media file is removed successfully.' })
        setModalLoading(false)
        reload()
      })
      .catch((error) => {
        handleFailure(error, { description: `Media file removal is failed. ${error}` })
        setModalLoading(false)
        reload()
      })
  }

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      title: 'ID',
    },
    {
      dataIndex: 'title',
      filteredValue: searchParams.title ? [searchParams.title] : null,
      key: 'title',
      sorter: true,
      title: 'Title',
      ...getTableHeaderSearchInput('sortingKeySearchSelenium', 'title'),
    },
    {
      dataIndex: 'barcodes_count',
      key: 'barcodes_count',
      title: 'Connected Barcodes Quantity',
      width: 350,
    },
    {
      dataIndex: 'image',
      key: 'image',
      render: (image, record) => {
        const lastMedia = last(record.media)
        return lastMedia ? (
          <div>
            <img alt={lastMedia.name} src={lastMedia.url} style={{ width: '50px' }} />
          </div>
        ) : (
          <div>No image</div>
        )
      },
      title: 'Image',
    },
    {
      className: 'sortingPageActionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:sorting_keys"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateSortingKeySelenium"
                onClick={() => handleUpdateClick(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />
          {record.barcodes_count === 0 && (
            <Can
              requiredPermission="delete:sorting_keys"
              yes={() => (
                <Popconfirm
                  cancelText="No"
                  className="action-list-button"
                  disabled={record.number_of_packages > 0}
                  okText="Yes"
                  onConfirm={() => handleDelete(record.id)}
                  title="Are you sure you want to delete this sorting key?"
                >
                  <Button
                    danger
                    disabled={record.number_of_packages > 0}
                    name="deleteSortingKeySelenium"
                    size="small"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              )}
            />
          )}
        </span>
      ),
      title: 'Action',
      width: 150,
    },
  ]

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const sortingKeys = (data && data.sorting_keys) || []

  return (
    <div>
      <Title>Sorting keys</Title>
      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:sorting_keys"
            yes={() => (
              <Button name="addNewSortingKeySelenium" onClick={handleAddClick} type="primary">
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            className="sortingPageTableSelenium x-scroll"
            columns={columns}
            dataSource={sortingKeys}
            loading={isLoading}
            metaData={data?.pagination_meta}
          />

          <CustomPagination metaData={data?.pagination_meta} />
        </Col>
      </Row>

      {isSortingKeyModalVisible && (
        <SortingKeyModal
          errorMessage={errorMessage}
          handleMediaRemove={handleMediaRemove}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedKey={selectedKey}
          setModalLoading={setModalLoading}
          setVisibility={setSortingKeyModalVisibility}
        />
      )}
    </div>
  )
}

export default SortingKeyPage
